<template>
    <div>
        <div class="border mb-3">
           <div class="border-bottom p-2 note" :class="{'note--editing': note.noteId === isEditingNoteId }" v-for="note in orderLineNotes" :key="note.noteId">
               <div class="d-flex align-items-center">
                   <h4 class="m-0 flex-grow-1">{{ note.topic }} <span v-if="note.noteId === isEditingNoteId"><status-chip color="orange">editing</status-chip></span></h4>
                   <mercur-button @click="edit(note)" class="btn btn-icon btn-icon-sm btn-blue"><i class="fas fa-edit"></i></mercur-button>
                   <mercur-button @click="remove(note)" class="btn btn-icon btn-icon-sm btn-danger"><i class="fas fa-trash"></i></mercur-button>
               </div>
               <p class="m-0">{{ note.content }}</p>
               <div class="d-flex note__info">
                   <p class="m-0 flex-grow-1">{{ note.owner.username }}</p>
                   <p class="m-0">{{ note.dateCreated }}</p>
               </div>
           </div>
        </div>
        <form @submit.prevent="submitForm" data-test="logFormNewNote">
            <mercur-input data-e2e="logFormNewNoteTopicInput" v-model="form.topic" :disabled="isOrderLineCancelled" :class="getValidationClass($v, 'topic')">
                Topic
                <template #note>
                    <span class="form-error" v-if="!$v.form.topic.required">Topic is required</span>
                </template>
            </mercur-input>
            <mercur-textarea data-e2e="logFormNewNoteNoteContent" rows="2" v-model="form.content" :disabled="isOrderLineCancelled" :class="getValidationClass($v, 'content')">
                Add note content
                <template #note>
                    <span class="form-error" v-if="!$v.form.content.required">Content is required</span>
                </template>
            </mercur-textarea>
            <div class="text-right">
                <template v-if="isEditingNoteId">
                    <mercur-button class="btn" @click="stopEdit" :disabled="loading || isOrderLineCancelled">Stop editing</mercur-button>
                    <mercur-button type="submit" v-if="hasPermission('MerchantCentral/updateNote')" class="btn btn-primary" :disabled="loading || isOrderLineCancelled">Update note</mercur-button>
                </template>
                <template v-else>
                    <mercur-button type="submit" data-e2e="logFormNewNoteAddNoteActionButton" v-if="hasPermission('MerchantCentral/addNote')" class="btn btn-primary" :disabled="loading || isOrderLineCancelled">Add new Note</mercur-button>
                </template>
            </div>
        </form>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import FormMixin from '@/mixins/Form'
import StatusChip from '@/components/utils/StatusChip'
import { required } from 'vuelidate/lib/validators'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    name: 'LogForm',
    mixins: [FormMixin],
    components: { StatusChip },
    data () {
        return {
            form: {},
            isEditingNoteId: null,
        }
    },
    validations: {
        form: {
            topic: {
                required,
            },
            content: {
                required,
            },
        },
    },
    computed: {
        ...mapState('order', ['order']),
        ...mapGetters('order', ['orderNotes']),
        isOrderLineCancelled () {
            const orderLines = this.order.orderLines
            const orderLineStatus = []

            orderLines.forEach(item => {
                orderLineStatus.push(item.orderLineStatus)
            })
            return orderLineStatus.includes('CANCELLED')
        },
        orderLineNotes () {
            if (!this.orderNotes) {
                return []
            }

            return this.orderNotes.filter(e => e.orderLineIds.includes(this.$route.params.orderLineId))
        },
    },
    methods: {
        ...mapActions('order', ['setNote', 'removeNote']),
        submitForm () {
            let action = CONFIG.API.ROUTES.NOTES.ADD.replace('{accountId}', this.$route.params.accountId).replace('{orderId}', this.order.orderId)
            if (this.isEditingNoteId) {
                action = CONFIG.API.ROUTES.NOTES.UPDATE.replace('{accountId}', this.$route.params.accountId).replace('{orderId}', this.order.orderId)
            }

            this.submit(action, { ...this.form, orderLineIds: [ this.$route.params.orderLineId ] }).then((data) => {
                this.form = {}

                this.setNote({
                    data: data.data,
                    noteId: data.data.noteId,
                })

                this.$root.$emit('notification:global', {
                    message: this.isEditingNoteId ? 'Note was updated' : 'Note was added',
                })
                this.isEditingNoteId = null
            })
        },
        remove (note) {
            const action = CONFIG.API.ROUTES.NOTES.REMOVE
                .replace('{accountId}', this.$route.params.accountId)
                .replace('{orderId}', this.order.orderId)
                .replace('{noteId}', note.noteId)
            this.loading = true
            this.$api.delete(action).then(({ data }) => {
                this.removeNote(data.data.noteId)
            }).finally(() => {
                this.loading = false
            })
        },
        edit (note) {
            this.isEditingNoteId = note.noteId
            this.form = JSON.parse(JSON.stringify(note))
        },
        stopEdit () {
            this.isEditingNoteId = null
            this.form = {}
        },
    },
}
</script>

<style lang="scss" scoped>
    .note {
        &__info {
            p {
                font-size: 11px;
                opacity: .8;
            }
        }

        &--editing {
            background-color: rgba(0,0,0,0.1);
        }
    }
</style>
